import React, { memo, useState } from 'react'
import { Modal } from 'antd'
import NewCustomer from './modals/NewCustomer'
import { connect } from 'react-redux'
import { setModal } from '../store/job/actions'

const Customer = memo(function Customer({ children, customerData = null, setOpenModal }) {
    const [modal, setModal] = useState(false)

    return (
        <>
            <div className='cursor w-fit' onClick={async () => {
                await setOpenModal(true)
                await setModal(true)
            }}>
                {children}
            </div>
            <Modal title={customerData ? "Edit Customer" : "Add New Customer"} closable={false} open={modal} footer={null} width={750} >
                <NewCustomer closeModal={() => setModal(false)} customerData={customerData} isEdit={customerData ? Object.keys(customerData)?.length > 0 : false} />
            </Modal>
        </>
    )
})


const mapStateToProps = (state) => {
    return {

    }
}

const mapDispachToProps = (dispatch) => ({
    setOpenModal: (payload) => dispatch(setModal(payload))
})


export default connect(mapStateToProps, mapDispachToProps)(Customer)